<template>
  <div class="sidenav-container">
    <v-app-bar dense color="white" fixed app v-if="!isDesktop">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-list-item-title class="d-flex justify-center">
        <h5>STA. CRUZ ACADEMY OF LUBAO, INC.</h5>
      </v-list-item-title>
    </v-app-bar>

    <v-navigation-drawer :permanent="isDesktop" app clipped fixed v-model="drawer">
      <template v-slot:prepend>
        <div class="title d-flex justify-center">
          <v-img contain src="@/assets/logo.png" class="logo"></v-img>
        </div>
      </template>
      <v-divider></v-divider>
      <v-list>
        <div>
          <v-list class="pa-0" flat>
            <v-list-item v-for="sidenav in filteredSideNav" :to="{ path: sidenav.to, params: { slug: sidenav.slug } }" :key="sidenav.title" class="side-menu my-1 pa-0" active-class="active" link>
              <v-list-item-icon class="active-icon pl-10 mr-3">
                <v-icon>{{ sidenav.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="active-title">{{ sidenav.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-list>
      <template v-slot:append>
        <div class="d-flex flex-column mb-10 align-center">
          <div class="d-flex flex-column align-center px-10 py-5 sidenav-btm rounded-lg">
            <template>
              <v-menu v-model="profileDialog" absolute top right>
                <template v-slot:activator="{ on, attrs }">
                  <v-img v-bind="attrs" v-on="on" max-width="50" contain :src="user.image" class="rounded-circle"></v-img>
                </template>
                <v-card>
                  <v-card-title class="d-flex justify-center">
                    <v-img max-width="100" contain :src="user.image" class="rounded-circle"></v-img>
                  </v-card-title>
                  <v-card-text>
                    <h5>{{ user.first_name }} {{ user.last_name }}</h5>
                    <span>{{ user.role }}</span>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn color="primary" dark small :href="`${accountUrl}/profile`" target="_blank"> My Profile </v-btn>
                    <v-btn @click="logout()" color="#Ff6465" dark small :loading="$store.state.authModule.btnLoading">Logout</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import config from '../config/auth0';

export default {
  name: 'SideNav',
  computed: {
    user() {
      return this.$store.state.authModule.user;
    },
    openProducts: {
      get() {
        return this.$route.meta.parent === 'products';
      },
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    filteredSideNav() {
      const role = this.user.role;

      return this.sideNavMenu.filter((navMenu) => {
        if (this.user.analytica_admin) {
          return navMenu.role == role || navMenu.role == 'shared' || navMenu.role == 'admin';
        }

        return navMenu.role == `${role}` || navMenu.role == 'shared';
      });
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('authModule/logout');
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  data() {
    return {
      accountUrl: config.accountUrl,
      profileDialog: false,
      drawer: false,
      group: null,
      sideNavMenu: [
        {
          title: 'Workplace',
          icon: 'mdi-bulletin-board',
          to: '/workplace',
          role: 'teacher',
          slug: 'workplace',
        },
        {
          title: 'Classroom',
          icon: 'mdi-billboard',
          to: '/classroom',
          role: 'student',
          slug: 'classroom',
        },
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          to: '/admin',
          role: 'admin',
          slug: 'dashboard',
        },
        {
          title: 'List Users',
          icon: 'mdi-account-multiple',
          to: '/users',
          role: 'admin',
          slug: 'users',
        },
        {
          title: 'List Subjects',
          icon: 'mdi-bookmark-box-multiple',
          to: '/subjects',
          role: 'admin',
          slug: 'subjects',
        },
        {
          title: 'List Sections',
          icon: 'mdi-format-list-group',
          to: '/sections',
          role: 'admin',
          slug: 'sections',
        },
        {
          title: 'List Strands',
          icon: 'mdi-source-branch',
          to: '/strands',
          role: 'admin',
          slug: 'strands',
        },
        // {
        //   title: "Profile",
        //   icon: "mdi-account-box",
        //   to: "/profile",
        //   role: "shared",
        //   slug: "profile",
        // },
      ],
    };
  },
};
</script>

<style scoped>
.logo {
  max-width: 150px !important;
}

.side-menu {
  height: 40px;
}
.side-menu:hover > .active-icon {
  background: var(--secondary-red);
  border-radius: 0px 20px 20px 0px;
}

.active .active-icon {
  background: var(--secondary-red);
  border-radius: 0px 20px 20px 0px;
  color: var(--main-red);
}

.active-icon {
  width: 110px;
  height: 40px;
}

.active .active-title {
  color: var(--main-red);
}

.v-list-item__icon {
  margin: 0;
}

.v-list-item--active {
  background: white !important;
}
.v-list-item {
  min-height: 40px;
}

.sidenav-btm {
  background-color: #f7f9fb;
}

.title {
  display: block;
}

.clickable {
  cursor: pointer;
}
</style>
